<template>
  <v-col class="col-12 my-2">
    <v-row class="parties-preview__item px-5 py-3" align="center" justify="space-between" no-gutters>
      <v-col class="col-6 preview__name">
        {{ fullName }}
      </v-col>

      <v-col class="col-4 preview__sign-info">
        <span :class="{ 'preview__sign-info--signed': isSignedOrApproved }">
          {{ signIdentityState }}
        </span>
      </v-col>

      <v-col class="col-1">
        <v-badge color="transparent" offset-x="9" offset-y="9">
          <template v-slot:badge>
            <v-icon :color="isSignedOrApproved ? '#35D883' : '#F7AE04'" x-small>
              {{ isSignedOrApproved ? 'mdi-check-circle' : 'mdi-alert' }}
            </v-icon>
          </template>

          <template v-slot:default>
            <v-icon :color="isSignedOrApproved ? '#7031B4' : '#95AFDA'">mdi-fountain-pen-tip</v-icon>
          </template>
        </v-badge>
      </v-col>

      <v-col class="col-1">
        <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" :disabled="isDisabled" small icon v-bind="attrs" v-on="on">
              <v-icon small>mdi-share-variant</v-icon>
            </v-btn>
          </template>

          <div class="share-link__wrapper pa-4">
            <div class="share-link__title pb-6">
              {{ $t('share_link.link_title') }}
            </div>

            <div class="share-link__link-wrapper pa-3">
              <div class="share-link__link-wrapper--text">
                <span>{{ signIdentityLink }}</span>
              </div>

              <div class="share-link__link-wrapper--icon">
                <v-icon color="#95AFDA" small @click="copyLinkToClipboard(signIdentityLink, $event)">
                  mdi-content-copy
                </v-icon>
              </div>
            </div>
          </div>
        </v-menu>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { defineComponent } from 'vue'
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities'

export default defineComponent({
  name: 'ContractCreatedPartyPreview',
  props: {
    signIdentityLinkData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const signIdentitiesStore = useSignIdentitiesStore()

    return {
      signIdentitiesStore,
    }
  },
  computed: {
    signIdentity() {
      return this.signIdentitiesStore.signIdentities?.find(
        (s) => `${s.id}` === `${this.signIdentityLinkData.signIdentityId}`,
      )
    },
    signIdentityLink() {
      return this.signIdentityLinkData?.link
    },
    signIdentityState() {
      return this.$t(
        [
          {
            key: 'docs.pending.approve',
            completed: !this.signIdentity?.is_approved && 'approve' === this.signIdentity?.contract_role,
          },
          {
            key: 'general.approved',
            completed: this.signIdentity?.is_approved && 'approve' === this.signIdentity?.contract_role,
          },
          {
            key: 'docs.pending.sign_certificate',
            completed: !this.signIdentity?.is_signed && 'sign_certificate' === this.signIdentity?.contract_role,
          },
          {
            key: 'docs.pending.sign_bank_id',
            completed: !this.signIdentity?.is_signed && 'sign_bank_id_sign' === this.signIdentity?.contract_role,
          },
          {
            key: 'docs.pending.notice',
            completed: !this.signIdentity?.is_signed && 'notice' === this.signIdentity?.contract_role,
          },
          {
            key: 'docs.pending.generic',
            completed: !this.signIdentity?.is_signed,
          },
          {
            key: 'signing.signed',
            completed: this.signIdentity?.is_signed,
          },
        ]?.find((e) => e.completed)?.key || 'docs.pending.generic',
      ).toUpperCase()
    },
    fullName() {
      return this.signIdentityLinkData?.full_name
    },
    isDisabled() {
      return this.signIdentityLinkData?.is_disabled || this.signIdentityLinkData?.is_completed
    },
    isSignedOrApproved() {
      return (
        this.signIdentity?.is_approved ||
        this.signIdentity?.is_signed ||
        this.signIdentity?.is_signed_with_bank_id_sign ||
        this.signIdentity?.is_signed_with_certificate
      )
    },
  },
  methods: {
    copyLinkToClipboard(signIdentityLink) {
      navigator?.clipboard?.writeText(signIdentityLink)

      this.$notification.success(this.$t('general.content_copied'))
    },
  },
})
</script>

<style lang="scss" scoped>
.parties-preview__item {
  box-sizing: border-box;
  border: 1px solid #d4dff0;
  border-radius: 5px;
  background-color: #ffffff;
}

.preview {
  &__name {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__sign-info {
    color: #f7ae04;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;

    &--signed {
      color: #35d883;
    }
  }
}

.share-link {
  &__wrapper {
    max-width: 334px;
    width: 100%;
    border: 1px solid #7031b4;
    border-radius: 4px;
    background-color: #ffffff;
  }

  &__title {
    color: #495057;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__link-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #ffffff;

    &--text {
      color: #424242;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17.5px;
      white-space: nowrap;
      overflow-x: auto;
    }

    &--icon {
      padding-left: 0.5rem;
    }
  }
}
</style>
